import React from "react";
import "./flyCoins.css"; // Ensure you import the CSS file

function FlyCoins() {
  return (
    <div className="wrap position-relative">
      <div className="wallet" id="wallet">
        {/* <div className="icon"> */}
        {/* <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="24" height="24"
            viewBox="0 0 458.5 458.5" fill="currentColor">
            <path
              d="M336.7 344c-22 0-39.9-18-39.9-39.9V238c0-22 18-39.8 39.9-39.8h105.7v-65.9c0-17-13.8-30.7-30.7-30.7h-381c-17 0-30.7 13.7-30.7 30.7v277.6c0 17 13.8 30.8 30.7 30.8h381c17 0 30.7-13.8 30.7-30.8V344H336.7z" />
            <path
              d="M440.5 220H336.7c-10 0-18 8-18 18V304c0 10 8 18 18 18h103.8c10 0 18-8 18-18V238c0-10-8-18-18-18zm-68 77a26 26 0 1 1 0-52 26 26 0 0 1 0 52zM358.2 45.2A39.7 39.7 0 0 0 308 20L152 71.6h214.9l-8.7-26.4z" />
          </svg> */}
        {/* </div> */}
        <div
          className="coin coin--animated"
          style={{ "--coin-delay": "0.1s", left: "calc(50% - 360px)" }}
        ></div>
        <div
          className="coin coin--animated"
          style={{ "--coin-delay": "0.2s", left: "calc(50% - 360px)" }}
        ></div>
        <div
          className="coin coin--animated"
          style={{ "--coin-delay": "0.3s", left: "calc(50% - 360px)" }}
        ></div>
        <div
          className="coin coin--animated"
          style={{ "--coin-delay": "0.4s", left: "calc(50% - 360px)" }}
        ></div>
        <div
          className="coin coin--animated"
          style={{ "--coin-delay": "0.5s", left: "calc(50% - 360px)" }}
        ></div>
        <div
          className="coin coin--animated"
          style={{ "--coin-delay": "0.1s", left: "calc(50% - 360px)" }}
        ></div>
        <div
          className="coin coin--animated"
          style={{ "--coin-delay": "0.2s", left: "calc(50% - 320px)" }}
        ></div>
        <div
          className="coin coin--animated"
          style={{ "--coin-delay": "0.3s", left: "calc(50% - 340px)" }}
        ></div>
        <div
          className="coin coin--animated"
          style={{ "--coin-delay": "0.4s", left: "calc(50% - 340px)" }}
        ></div>
        <div
          className="coin coin--animated"
          style={{ "--coin-delay": "0.5s", left: "calc(50%)" }}
        ></div>
        <div
          className="coin coin--animated"
          style={{ "--coin-delay": "0.1s", left: "calc(50% + 300px)" }}
        ></div>
        <div
          className="coin coin--animated"
          style={{ "--coin-delay": "0.2s", left: "calc(50% + 300px)" }}
        ></div>
        <div
          className="coin coin--animated"
          style={{ "--coin-delay": "0.3s", left: "calc(50% + 300px)" }}
        ></div>
        <div
          className="coin coin--animated"
          style={{ "--coin-delay": "0.4s", left: "calc(50% + 300px)" }}
        ></div>
        <div
          className="coin coin--animated"
          style={{ "--coin-delay": "0.5s", left: "calc(50% + 300px)" }}
        ></div>
        <div
          className="coin coin--animated"
          style={{ "--coin-delay": "0.1s", left: "calc(50% + 280px)" }}
        ></div>
        <div
          className="coin coin--animated"
          style={{ "--coin-delay": "0.1s", left: "calc(50% - 320px)" }}
        ></div>
        <div
          className="coin coin--animated"
          style={{ "--coin-delay": "0.2s", left: "calc(50% - 320px)" }}
        ></div>
        <div
          className="coin coin--animated"
          style={{ "--coin-delay": "0.3s", left: "calc(50% - 320px)" }}
        ></div>
        <div
          className="coin coin--animated"
          style={{ "--coin-delay": "0.4s", left: "calc(50% - 320px)" }}
        ></div>
        <div
          className="coin coin--animated"
          style={{ "--coin-delay": "0.5s", left: "calc(50% - 320px)" }}
        ></div>
        <div
          className="coin coin--animated"
          style={{ "--coin-delay": "0.1s", left: "calc(50% - 320px)" }}
        ></div>
        <div
          className="coin coin--animated"
          style={{ "--coin-delay": "0.2s", left: "calc(50% - 320px)" }}
        ></div>
        <div
          className="coin coin--animated"
          style={{ "--coin-delay": "0.3s", left: "calc(50% - 320px)" }}
        ></div>
        <div
          className="coin coin--animated"
          style={{ "--coin-delay": "0.4s", left: "calc(50% - 320px)" }}
        ></div>
        <div
          className="coin coin--animated"
          style={{ "--coin-delay": "0.5s", left: "calc(50%)" }}
        ></div>
        <div
          className="coin coin--animated"
          style={{ "--coin-delay": "0.1s", left: "calc(50% + 280px)" }}
        ></div>
        <div
          className="coin coin--animated"
          style={{ "--coin-delay": "0.2s", left: "calc(50% + 280px)" }}
        ></div>
        <div
          className="coin coin--animated"
          style={{ "--coin-delay": "0.3s", left: "calc(50% + 280px)" }}
        ></div>
        <div
          className="coin coin--animated"
          style={{ "--coin-delay": "0.4s", left: "calc(50% + 280px)" }}
        ></div>
        <div
          className="coin coin--animated"
          style={{ "--coin-delay": "0.5s", left: "calc(50% + 280px)" }}
        ></div>
        <div
          className="coin coin--animated"
          style={{ "--coin-delay": "0.1s", left: "calc(50% + 280px)" }}
        ></div>
        <div
          className="coin coin--animated"
          style={{ "--coin-delay": "0.2s", left: "calc(50% + 280px)" }}
        ></div>
        <div
          className="coin coin--animated"
          style={{ "--coin-delay": "0.3s", left: "calc(50% + 240px)" }}
        ></div>
        <div
          className="coin coin--animated"
          style={{ "--coin-delay": "0.4s", left: "calc(50% + 240px)" }}
        ></div>
        <div
          className="coin coin--animated"
          style={{ "--coin-delay": "0.5s", left: "calc(50% + 240px)" }}
        ></div>
        <div
          className="coin coin--animated"
          style={{ "--coin-delay": "0.15s", left: "calc(50% - 240px)" }}
        ></div>
        <div
          className="coin coin--animated"
          style={{ "--coin-delay": "0.25s", left: "calc(50% - 240px)" }}
        ></div>
        <div
          className="coin coin--animated"
          style={{ "--coin-delay": "0.35s", left: "calc(50% - 240px)" }}
        ></div>
        <div
          className="coin coin--animated"
          style={{ "--coin-delay": "0.45s", left: "calc(50% - 200px)" }}
        ></div>
        <div
          className="coin coin--animated"
          style={{ "--coin-delay": "0.55s", left: "calc(50% - 200px)" }}
        ></div>
        <div
          className="coin coin--animated"
          style={{ "--coin-delay": "0.45s", left: "calc(50% - 200px)" }}
        ></div>
        <div
          className="coin coin--animated"
          style={{ "--coin-delay": "0.55s", left: "calc(50% - 200px)" }}
        ></div>
        <div
          className="coin coin--animated"
          style={{ "--coin-delay": "0.45s", left: "calc(50% - 200px)" }}
        ></div>
        <div
          className="coin coin--animated"
          style={{ "--coin-delay": "0.55s", left: "calc(50% - 200px)" }}
        ></div>
        <div
          className="coin coin--animated"
          style={{ "--coin-delay": "0.35s", left: "calc(50% - 180px)" }}
        ></div>
        <div
          className="coin coin--animated"
          style={{ "--coin-delay": "0.55s", left: "calc(50% - 180px)" }}
        ></div>
        <div
          className="coin coin--animated"
          style={{ "--coin-delay": "0.35s", left: "calc(50% - 180px)" }}
        ></div>
        <div
          className="coin coin--animated"
          style={{ "--coin-delay": "0.55s", left: "calc(50% - 180px)" }}
        ></div>
        <div
          className="coin coin--animated"
          style={{ "--coin-delay": "0.25s", left: "calc(50% - 180px)" }}
        ></div>
        <div
          className="coin coin--animated"
          style={{ "--coin-delay": "0.45s", left: "calc(50% - 180px)" }}
        ></div>
        <div
          className="coin coin--animated"
          style={{ "--coin-delay": "0.25s", left: "calc(50% - 180px)" }}
        ></div>
        <div
          className="coin coin--animated"
          style={{ "--coin-delay": "0.15s", left: "calc(50% - 150px)" }}
        ></div>
        <div
          className="coin coin--animated"
          style={{ "--coin-delay": "0.3s", left: "calc(50% - 150px)" }}
        ></div>
        <div
          className="coin coin--animated"
          style={{ "--coin-delay": "0.4s", left: "calc(50% - 150px)" }}
        ></div>
        <div
          className="coin coin--animated"
          style={{ "--coin-delay": "0.5s", left: "calc(50% - 150px)" }}
        ></div>
        <div
          className="coin coin--animated"
          style={{ "--coin-delay": "0.1s", left: "calc(50% + 150px)" }}
        ></div>
        <div
          className="coin coin--animated"
          style={{ "--coin-delay": "0.2s", left: "calc(50% + 150px)" }}
        ></div>
        <div
          className="coin coin--animated"
          style={{ "--coin-delay": "0.3s", left: "calc(50% + 100px)" }}
        ></div>
        <div
          className="coin coin--animated"
          style={{ "--coin-delay": "0.4s", left: "calc(50% + 100px)" }}
        ></div>
        <div
          className="coin coin--animated"
          style={{ "--coin-delay": "0.5s", left: "calc(50% + 100px)" }}
        ></div>
        <div
          className="coin coin--animated"
          style={{ "--coin-delay": "0.2s", left: "calc(50% + 100px)" }}
        ></div>
        <div
          className="coin coin--animated"
          style={{ "--coin-delay": "0.3s", left: "calc(50% + 100px)" }}
        ></div>
        <div
          className="coin coin--animated"
          style={{ "--coin-delay": "0.4s", left: "calc(50% + 100px)" }}
        ></div>
        <div
          className="coin coin--animated"
          style={{ "--coin-delay": "0.5s", left: "calc(50% + 100px)" }}
        ></div>
        <div
          className="coin coin--animated"
          style={{ "--coin-delay": "0.2s", left: "calc(50% + 50px)" }}
        ></div>
        <div
          className="coin coin--animated"
          style={{ "--coin-delay": "0.3s", left: "calc(50% + 50px)" }}
        ></div>



        <div
          className="coin coin--animated"
          style={{ "--coin-delay": "0.2s", left: "calc(50% + 250px)" }}
        ></div>
        <div
          className="coin coin--animated"
          style={{ "--coin-delay": "0.3s", left: "calc(50% + 200px)" }}
        ></div>
        <div
          className="coin coin--animated"
          style={{ "--coin-delay": "0.4s", left: "calc(50% + 200px)" }}
        ></div>
        <div
          className="coin coin--animated"
          style={{ "--coin-delay": "0.5s", left: "calc(50% + 200px)" }}
        ></div>
        <div
          className="coin coin--animated"
          style={{ "--coin-delay": "0.2s", left: "calc(50% + 00px)" }}
        ></div>
        <div
          className="coin coin--animated"
          style={{ "--coin-delay": "0.3s", left: "calc(50% + 200px)" }}
        ></div>
        <div
          className="coin coin--animated"
          style={{ "--coin-delay": "0.4s", left: "calc(50% + 200px)" }}
        ></div>
        <div
          className="coin coin--animated"
          style={{ "--coin-delay": "0.5s", left: "calc(50% + 200px)" }}
        ></div>
        <div
          className="coin coin--animated"
          style={{ "--coin-delay": "0.2s", left: "calc(50% + 260px)" }}
        ></div>
        <div
          className="coin coin--animated"
          style={{ "--coin-delay": "0.3s", left: "calc(50% + 250px)" }}
        ></div>
      </div>
    </div>
  );
}

export default FlyCoins;
