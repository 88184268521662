import axios from "axios";
import { server } from "./keys";


export const userInstance = () =>{
    return axios.create({
      baseURL: `${server}/v1/users`,
      headers: {
        "Permissions-Policy": "geolocation=*",
      },
    });
}