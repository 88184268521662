import React, {useEffect, useState} from "react";
import mainImg from "../../../../assets/bunnyCar.png";
import { CoinIcon } from "../../../../common-componants/icons/icons";
import { SVGLockComponent } from "../tabCard";
import "./cardSpecial.css";
import "../tabCard/tabCard.css"
import { formatToK } from "../../utils";
import coinImage from "../../../../assets/gold-coin.png";




const CardSpecial = ({ bgColor, data, user,isLock }) => {
  


  return (
    <div className=" CardSpecial">
      {isLock && data.dependency ? (
      <div className="NewSpecial-banner" style={bgColor}>
        <div className="overlay">
          <div>
            <div className="overlay-image">
              <p className="text-white ">
                <SVGLockComponent />
              </p>
            </div>
            <h3 className="">{data.designation}</h3>
            <p>{data.specialSlogan}</p>
            <p>Profit per hour 💰 +{formatToK(data.pph)}</p>
          </div>
        </div>

        <div className="NewSpecial-banner-bottom">
          <div className="newSpecial-bottom">
            <span>|v| {data.level}</span>
            <span>
              {/* <CoinIcon className="coinHeight" /> */}
            <img src={coinImage} alt="" className="coinHeight" />
            {`${data.dependency?.mine} lvl ${data.dependency?.requiredLevel}`}</span>
          </div>
        </div>
      </div>
      )
      :(
      <div className="NewSpecial-banner" style={bgColor}>
        <div className="overlay">
          <div>
            <div className="overlay-image">
              <img src={data.imgUrl} alt="" />
            </div>
            <h3 className="">{data.designation}</h3>
            <p>{data.specialSlogan}</p>
            <p>Profit per hour 💰 +{formatToK(data.pph)}</p>
          </div>
        </div>

        <div className="NewSpecial-banner-bottom">
          <div className="newSpecial-bottom">
            <span>|v| {data.level}</span>
            <span>
              {/* <CoinIcon className="coinHeight" /> */}
              <img src={coinImage} alt="" className="coinHeight" />
              {formatToK(data.cost)}</span>
            {/* <span>Expired</span> */}
          </div>
        </div>
      </div>
      )}
      
    </div>
  );
};

export default CardSpecial;
