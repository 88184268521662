import React from 'react'
import Home from '../../componants/home'

function HomePage() {
  return (
   <>
   <Home />
   </>
  )
}

export default HomePage
