import React, { useContext, } from "react";
import "./home.css";
import { Link } from "react-router-dom";
import profilePicture from "../../assets/profile.jpeg"
import WalletSection from "./componants/walletSection";
import PlayScreen from "./componants/playScreen";
import UserContext from "../../context/userContext";

function Home() {
  const { user } = useContext(UserContext);
 
  return (
    <>
        <div className="homeMainWrap">
          <div className="px-3">
            <div className="playGameWrapper">
              {/* <Link to="/profile" style={{textDecoration: "none"}}>    ------As there is only on skin so commented this*/}  
              <Link style={{textDecoration: "none"}}>
              <div className="userNBameWrap d-flex align-items-center">
                <span>
                  {/* <img src={user?.selectedSkin} alt="profile" /> */}
                  <img src={profilePicture} alt="profile" />
                </span>
                <p>{user?.name} (Leader)</p>
              </div>
              </Link>
            </div>
            <WalletSection user={user}/>
          </div>
          <PlayScreen />
        </div>
    </>
  );
}

export default Home;
