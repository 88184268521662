import React from "react";
import "./flyCoinsSec.css";

function FlyCoinsSec() {
  return (
    <div className="second wrap">
      <div className="wallet" id="wallet">
        <div className="icon"></div>
      
        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 500px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.08s",
          }}
        ></div>
        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 460px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.05s",
          }}
        ></div>
        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 420px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.15s",
          }}
        ></div>
        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 380px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.10s",
          }}
        ></div>
        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 340px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.11s",
          }}
        ></div>
        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 300px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.12s",
          }}
        ></div>
        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 260px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.11s",
          }}
        ></div>
        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 220px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.18s",
          }}
        ></div>
        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 180px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.10s",
          }}
        ></div>
        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 140px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.08s",
          }}
        ></div>
        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 100px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.14s",
          }}
        ></div>
        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 60px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.30s",
          }}
        ></div>
        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 20px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.10s",
          }}
        ></div>
        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 0px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.16s",
          }}
        ></div>

        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 500px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.34s",
          }}
        ></div>
        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 460px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.05s",
          }}
        ></div>
        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 420px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.10s",
          }}
        ></div>
        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 380px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.12s",
          }}
        ></div>
        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 340px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.11s",
          }}
        ></div>
        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 300px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.10s",
          }}
        ></div>
        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 260px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.08s",
          }}
        ></div>
        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 220px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.20s",
          }}
        ></div>
        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 180px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.24s",
          }}
        ></div>
        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 140px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.09s",
          }}
        ></div>
        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 100px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.10s",
          }}
        ></div>
        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 60px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.24s",
          }}
        ></div>
        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 20px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.18s",
          }}
        ></div>
        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 0px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.13s",
          }}
        ></div>

        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 500px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.28s",
          }}
        ></div>
        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 460px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.15s",
          }}
        ></div>
        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 420px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.17s",
          }}
        ></div>
        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 380px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.26s",
          }}
        ></div>
        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 340px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.17s",
          }}
        ></div>
        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 300px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.16s",
          }}
        ></div>
        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 260px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.17s",
          }}
        ></div>
        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 220px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.28s",
          }}
        ></div>
        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 180px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.18s",
          }}
        ></div>
        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 140px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.17s",
          }}
        ></div>
        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 100px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.18s",
          }}
        ></div>
        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 60px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.36s",
          }}
        ></div>
        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 20px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.18s",
          }}
        ></div>
        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 0px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.28s",
          }}
        ></div>

        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 420px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.20s",
          }}
        ></div>
        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 360px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.38s",
          }}
        ></div>
        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 280px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.25s",
          }}
        ></div>
        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 220px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.11s",
          }}
        ></div>
        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 160px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.25s",
          }}
        ></div>
        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 100px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.34s",
          }}
        ></div>
        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 40px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.22s",
          }}
        ></div>
        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 00px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.27s",
          }}
        ></div>
        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 100px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.24s",
          }}
        ></div>
        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 60px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.24s",
          }}
        ></div>
        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 20px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.18s",
          }}
        ></div>
        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 0px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.20s",
          }}
        ></div>

        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 420px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.26s",
          }}
        ></div>
        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 360px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.25s",
          }}
        ></div>
        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 280px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.16s",
          }}
        ></div>
        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 220px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.27s",
          }}
        ></div>
        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 160px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.25s",
          }}
        ></div>
        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 100px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.28s",
          }}
        ></div>
        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 40px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.27s",
          }}
        ></div>
        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 00px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.26s",
          }}
        ></div>
        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 100px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.34s",
          }}
        ></div>
        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 60px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.28s",
          }}
        ></div>
        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 20px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.29s",
          }}
        ></div>
        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 0px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.30s",
          }}
        ></div>

        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 420px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.30s",
          }}
        ></div>
        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 360px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.33s",
          }}
        ></div>
        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 280px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.32s",
          }}
        ></div>
        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 220px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.34s",
          }}
        ></div>
        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 160px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.32s",
          }}
        ></div>
        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 100px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.33s",
          }}
        ></div>
        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 40px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.34s",
          }}
        ></div>
        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 00px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.35s",
          }}
        ></div>
        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 100px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.36s",
          }}
        ></div>
        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 60px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.34s",
          }}
        ></div>
        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 20px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.36s",
          }}
        ></div>
        <div
          className="coin coin--animated"
          style={{
            "--coin-to-x": "calc(-100px + 0px)",
            "--coin-to-y": "20px",
            "--coin-delay": "0.35s",
          }}
        ></div>
     
      </div>
    </div>
  );
}

export default FlyCoinsSec;
