import React from 'react'
import Profile from '../../componants/profile'

function ProfilePage() {
  return (
    <div>
      <Profile />
    </div>
  )
}

export default ProfilePage
