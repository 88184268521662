import React, { useState, useContext,useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./exchange.css";
import { BinanceIcon, CrossIcon } from "../../common-componants/icons/icons";
import arrow from "../../assets/svgviewer-png-output.png";
import Zeus from "../../assets/icons/Zeus.png";
import Hera from "../../assets/icons/Hera.png";
import Poseidon from "../../assets/icons/Poseidon.png";
import Athena from "../../assets/icons/Athena.png";
import Krishna from "../../assets/icons/Krishna.png";
import Varaha from "../../assets/icons/Varaha.png";
import Rama from "../../assets/icons/Rama.png";
import Odin from "../../assets/icons/Odin.png";
import Loki from "../../assets/icons/Loki.png";
import Thor from "../../assets/icons/Thor.png";
import Balder from "../../assets/icons/Balder.png";
import Wukong from "../../assets/icons/Sun Wukong.png"; 
import UserContext from "../../context/userContext";
import { userInstance } from "../../config/axios";
import { toast } from "react-toastify";

function ChooseExchange() {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [selectedExchange, setSelectedExchange] = useState("");
  const [selectedImage, setSelectedImage] = useState(""); 
  const { user, setUser } = useContext(UserContext);
  const [exName,setExName] = useState("")
  const [clicked, setClicked] = useState(false)
  const location = useLocation()
  useEffect(()=>{
    if (window.Telegram.WebApp && location.pathname !== "/") {
      const { WebApp } = window.Telegram;
      WebApp.ready();
      WebApp.BackButton.show();
      WebApp.BackButton.onClick(() => {
        window.history.back();
        WebApp.BackButton.hide();
      });
    }
  },[])

  const showPopup = (name, image) => {
    setSelectedExchange(name);
    setSelectedImage(image); 
    setIsPopupVisible(true);
  };

  const hidePopup = () => {
    setIsPopupVisible(false);
  };

  const changeExchange = async () => {
    if(!clicked){
      try {
        setClicked(true)
        const resp = await userInstance().post("/updateExchange", {
          userId: user?.userId,
          exchange: selectedExchange,
        });
        setUser(resp.data);
        toast.success("Good luck leader!",{toastId:"exchange"});
        setClicked(false)
        hidePopup();
      } catch (error) {
        toast.error(error.message, { toastId: "changeExchange" });
        console.log("change diety error", error);
      }
    }
  };

  const exchanges = [
    { name: "Zeus", Icon: Zeus },
    { name: "Hera", Icon: Hera },
    { name: "Poseidon", Icon: Poseidon },
    { name: "Athena", Icon: Athena },
    { name: "Krishna", Icon: Krishna },
    { name: "Varaha", Icon: Varaha },
    { name: "Rama", Icon: Rama },
    { name: "Odin", Icon: Odin },
    { name: "Loki", Icon: Loki },
    { name: "Thor", Icon: Thor },
    { name: "Balder", Icon: Balder },
    { name: "Sun Wukong", Icon: Wukong },
  ];

  return (
    <>
      <div className="settingMainWrap px-3">
        <div className="Settingwrap pt-3">
          <h1>Your Deity</h1>
        </div>
        <div className="firstaselectDiv mt-3">
          {exchanges.map((exchange) => (
            <div
              className="exchangeMainCardWrap d-flex justify-content-between align-items-center"
              onClick={() => showPopup(exchange.name, exchange.Icon)}
              key={exchange.name}
            >
              <div className="d-flex align-items-center">
                <img src={exchange.Icon} alt="" style={{ width: "45px", height: "45px" }} />
                <span className="exchangeCardTxt ml-2">{exchange.name}</span>
              </div>
              <div>
                {user?.selectedExchange === exchange.name ? (
                  <p className="exchangeCardTxt ml-2">✔</p>
                ) : (
                  <img src={arrow} alt="" />
                )}
              </div>
            </div>
          ))}
        </div>
      </div>

      {isPopupVisible && (
        <div className="deletePopupWrap cardRoundedWrap py-5 px-3">
          <div className="crossBtnWrap">
            <span onClick={hidePopup}>
              <CrossIcon />
            </span>
          </div>
          <div className="binacePopupWrap position-relative px-2 mt-3">
            <div className="contentWrappopup popupCard text-center">
              <span className="roundedLogoSpan">
                <img src={selectedImage} alt={selectedExchange} style={{ width: "65px", height: "65px" }} />
              </span>
              <h1 className="mt-5">
                You've signed a contract with {selectedExchange}
              </h1>
            </div>
          </div>
          <div className="btnsDivWrap airDropsBtnWrap mt-4">
            <button onClick={changeExchange}>Good luck, Leader!</button>
          </div>
        </div>
      )}
    </>
  );
}

export default ChooseExchange;
