import React from 'react'
import RedChipper from '../../componants/redChipper'

function RedChipperPage() {
  return (
    <div>
      <RedChipper />
    </div>
  )
}

export default RedChipperPage
