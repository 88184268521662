import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function InviteHandler({ setKentId }) {
  const location = useLocation();
  // get and update user who send the invite
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const startApps = params.get('tgWebAppStartParam');

    if (startApps) {
      const idMatch = startApps.match(/kentId(\d+)/);
      if (idMatch && idMatch[1]) {
        setKentId(idMatch[1]);
      }
    }
  }, [location, setKentId]);

  return null; // This component does not render anything
}

export default InviteHandler;
