import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTelegram, faYoutube } from "@fortawesome/free-brands-svg-icons";
import React from "react";
import { Link } from "react-router-dom";

function WelcomeScreen() {
    
  return (
    <div>
      <div className="LoaderBG position-relative">
        <div class="loadingspinner">
          <div id="square1"></div>
          <div id="square2"></div>
          <div id="square3"></div>
          <div id="square4"></div>
          <div id="square5"></div>
        </div>
        <div className="contendDiv text-center">
          <div>
            <h1>Bunnies AI Token</h1>
            <h4>will be launched</h4>
            <span>on TON</span>
          </div>
          <div className="socialMediaDiv">
            <h4>stay tuned</h4>
            <h2>More info in official channels</h2>
            <div className="d-flex align-items-center SocialiconsWrap">
              <Link>
                <FontAwesomeIcon icon={faTelegram} height="60px" />
              </Link>
              <Link className="youtube">
                <FontAwesomeIcon icon={faYoutube} height="60px" />
              </Link>
              <Link className="youtube">
                <FontAwesomeIcon icon={faYoutube} height="60px" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WelcomeScreen;
