import React, {
  useContext,
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef
} from "react";
import "./mine.css";
import "./componants/card/card.css";
import goldCoins from "../../assets/10992280.png";
import { QuestionMarkImg } from "../../common-componants/icons/icons";
import WalletSection from "../home/componants/walletSection";
import {
  CrossIcon,
  InfoIcon,
  WalletIcon,
} from "../../common-componants/icons/icons";
import tapBunny from "../../assets/bunnie.png";
import goldCoin from "../../assets/gold-coin.png";
import Tabs from "./componants/tabSection";
import walletBunny from "../../assets/popUpImg.png";
import EnergyImg from "../../assets/lighting.png";
import BoostImg from "../../assets/Rocket.png";
import { Link } from "react-router-dom";
import UserContext from "../../context/userContext";
import { userInstance } from "../../config/axios";
import { toast } from "react-toastify";
import { formatNumberWithCommas } from "./utils";
import socket from "../../config/socket";
import Zeus from "../../assets/tap-icons/Zeus.png";
import Hera from "../../assets/tap-icons/Hera.png";
import Poseidon from "../../assets/tap-icons/Poseidon.png";
import Athena from "../../assets/tap-icons/Athena.png";
import Krishna from "../../assets/tap-icons/Krishna.png";
import Varaha from "../../assets/tap-icons/Varaha.png";
import Rama from "../../assets/tap-icons/Rama.png";
import Odin from "../../assets/tap-icons/Odin.png";
import Loki from "../../assets/tap-icons/Loki.png";
import Thor from "../../assets/tap-icons/Thor.png";
import Balder from "../../assets/tap-icons/Balder.png";
import Wukong from "../../assets/tap-icons/Sun Wukong.png";
import useIntersectionObserver from "./useintersectionObserver";
import { useLocation } from "react-router-dom";

function Mine() {
  const { user, setUser,setWalletVisible } = useContext(UserContext);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [combos, setCombos] = useState([]);
  const [mines, setMines] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [positions, setPositions] = useState([]);
  const [flyNumber, setFlyNumber] = useState(false);
  const [matchingCards, setMatchingCards] = useState();
  const [goldCoinCount, setGoldCoinCount] = useState("Loadin...");
  const [comboClicked, setComboClicked] = useState(false);
  const [exchangeIcon, setExchangeIcon] = useState();
  const [tiltStyle, setTiltStyle] = useState({ transform: "none" });
  const circleRef = useRef(null);
  const location = useLocation()
  useEffect(()=>{
    if (window.Telegram.WebApp && location.pathname !== "/") {
      const { WebApp } = window.Telegram;
      WebApp.ready();
      WebApp.BackButton.show();
      WebApp.BackButton.onClick(() => {
        window.history.back();
        WebApp.BackButton.hide();
      });
    }
  },[])

  const walletRef = useRef(null);

  // Detect visibility of the wallet section
  const isVisible = useIntersectionObserver(walletRef, { threshold: 0.5 });

  useEffect(() => {
    // Update context with visibility state
    setWalletVisible(isVisible);
  }, [isVisible, setWalletVisible]);

  const exchanges = [
    { name: "Zeus", Icon: Zeus },
    { name: "Hera", Icon: Hera },
    { name: "Poseidon", Icon: Poseidon },
    { name: "Athena", Icon: Athena },
    { name: "Krishna", Icon: Krishna },
    { name: "Varaha", Icon: Varaha },
    { name: "Rama", Icon: Rama },
    { name: "Odin", Icon: Odin },
    { name: "Loki", Icon: Loki },
    { name: "Thor", Icon: Thor },
    { name: "Balder", Icon: Balder },
    { name: "Sun Wukong", Icon: Wukong },
  ];
  useEffect(() => {
    exchanges.map((exchange) => {
      if (exchange.name === user?.selectedExchange) {
        setExchangeIcon(exchange.Icon);
      }
    });
  }, [user]);

  const handleCircleClick = (event) => {
    // if (navigator.vibrate) {
    //   console.log(navigator.vibrate, "hiiiiiiiiii")
    //   navigator.vibrate(200);
    // }
    const circle = circleRef.current;
    if (!circle) return;

    const rect = circle.getBoundingClientRect();

    const circleCenterX = rect.left + (rect.right - rect.left) / 2.5;
    const circleCenterY = rect.top + (rect.bottom - rect.top) / 2.5;

    const clickX = event.clientX;
    const clickY = event.clientY;

    const diffX = clickX - circleCenterX;
    const diffY = clickY - circleCenterY;

    const tiltX = diffY * 0.13;
    const tiltY = -diffX * 0.13;

    setTiltStyle({ transform: `rotateX(${tiltX}deg) rotateY(${tiltY}deg)` });

    setTimeout(() => {
      setTiltStyle({ transform: "none" });
    }, 150);
  };

  const handleTouchStart = async (event) => {
    // const rect = event.target.getBoundingClientRect();
    try {
      const newPositions = [];
      const resp = await userInstance().post("/updateToken", {
        userId: user._id,
      });
      setUser(resp?.data?.updatedUser);
      for (let i = 0; i < event.touches.length; i++) {
        const touch = event.touches[i];

        if (user?.remainingPerMinute > 0) {
          if (window.Telegram.WebApp) {
            const { WebApp } = window.Telegram;
            WebApp.ready();
            const noti = WebApp.HapticFeedback
            noti.notificationOccurred("success")
          }
          // const rect = touch.target.getBoundingClientRect();
          const container = document.getElementById('container');
          const rect = container.getBoundingClientRect();
          const x = touch.clientX - rect.left;
          const y = touch.clientY - rect.top;
          newPositions.push({ top: y, left: x });
        }
      }
      setFlyNumber(true);
      setPositions((prevPositions) => [...prevPositions, ...newPositions]);
    } catch (error) {
      toast.error(error.message, { toastId: "handleTouchStart" });
    }
  };

  useEffect(() => {
    if (flyNumber) {
      const timer = setTimeout(() => {
        setFlyNumber(false);
        setPositions([]); // Clear positions after a timeout
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [flyNumber]);

  const showPopup = () => {
    setIsPopupVisible(true);
  };

  const hidePopup = () => {
    setIsPopupVisible(false);
  };

  const getCombo = async () => {
    try {
      const resp = await userInstance().get("/getCombo");
      setCombos(resp.data[0].designation);
    } catch (error) {
      toast.error(error.message, { toastId: "getCombo" });
    }
  };

  const getSpecial = async () => {
    try {
      const resp = await userInstance().post("/mines", {
        userId: user?._id,
        mineType: "Special",
      });
      setMines(resp.data.userOwnedMines);
    } catch (error) {
      toast.error(error.message, { toastId: "mines" });
    }
  };
  useEffect(() => {
    (async () => {
      if (user) {
        // getSpecial();
        setMines(user?.minutlyGain?.subscribed)
      } else {
        setMines([]);
      }
    })();
    getCombo();
  }, [user?.minutlyGain?.value]);
  const { pattern, imageUrls } = useMemo(() => {
    const getCardPattern = () => {
      const matchingComboNames = combos.filter((combo) =>
        mines?.some((mine) => mine.designation === combo)
      );
      setMatchingCards(matchingComboNames.length);
      const pattern = [];
      const imageUrls = {};

      if (matchingComboNames.length === 3) {
        setDisabled(false);
        for (let i = 0; i < 3; i++) {
          const designation = matchingComboNames[i];
          pattern.push("Combo");
          imageUrls[`Combo-${i}`] = mines.find(
            (m) => m.designation === designation
          )?.imgUrl;
        }
      } else if (matchingComboNames.length === 2) {
        // Two are combos and one is mystery
        pattern.push("Combo");
        pattern.push("Combo");
        pattern.push("Mystery");

        imageUrls["Combo-0"] = mines.find(
          (m) => m.designation === matchingComboNames[0]
        )?.imgUrl;
        imageUrls["Combo-1"] = mines.find(
          (m) => m.designation === matchingComboNames[1]
        )?.imgUrl;
      } else if (matchingComboNames.length === 1) {
        // One is combo and two are mystery
        pattern.push("Combo");
        pattern.push("Mystery");
        pattern.push("Mystery");

        imageUrls["Combo-0"] = mines.find(
          (m) => m.designation === matchingComboNames[0]
        )?.imgUrl;
      } else {
        // If none are combos, all are mystery
        pattern.push("Mystery");
        pattern.push("Mystery");
        pattern.push("Mystery");
      }
      console.log("Here", pattern);
      return { pattern, imageUrls };
    };
    return getCardPattern();
  }, [combos, mines]);

  const claimCombo = async () => {
    if (!comboClicked) {
      try {
        setComboClicked(true);
        const resp = await userInstance().post("/claimCombo", {
          userId: user?._id,
          // amount: 5000000
        });
        setUser(resp.data);
        toast.success("Claimed!", { toastId: "combo" });
      } catch (error) {
        toast.error(error.message, { toastId: "comboerror" });
        console.log("Error while claiming combo", error);
      }
    }
  };

  useEffect(() => {
    setGoldCoinCount(parseFloat(user?.tokensWhenComesOnline).toFixed());
  }, [user]);

  useEffect(() => {
    socket.on("updateUser", (updatedUser) => {
      setUser(updatedUser);
      setGoldCoinCount(
        parseFloat(updatedUser?.tokensWhenComesOnline).toFixed()
      );
    });
  }, []);

  return (
    <>
      <div className="mineMainWrap">
        <div className="px-3" ref={walletRef}>
          <WalletSection user={user} />
        </div>
        <div className="cardRoundedWrap py-4 px-3 mt-3">
          <div className="text-end timeDiv">
            <span onClick={showPopup}>
              {/* 01:02:38 <InfoIcon /> */}
              <InfoIcon />
            </span>
          </div>
          <div className="comboDailyWrap d-flex justify-content-between align-items-center py-2 px-3">
            <div>
              <span className="text"> combo</span>
            </div>
            {matchingCards === 1 ? (
              <div className="d-flex">
                <span className="filled"></span>
                <span className="circle"></span>
                <span className="circle"></span>
              </div>
            ) : matchingCards === 2 ? (
              <div className="d-flex">
                <span className="filled"></span>
                <span className="filled"></span>
                <span className="circle"></span>
              </div>
            ) : matchingCards === 3 ? (
              <div className="d-flex">
                <span className="filled"></span>
                <span className="filled"></span>
                <span className="filled"></span>
              </div>
            ) : (
              <div className="d-flex">
                <span className="circle"></span>
                <span className="circle"></span>
                <span className="circle"></span>
              </div>
            )}

            <div className="btnDiv">
              {!disabled ? (
                <div>
                  {!user.claimedCombo ? (
                    <button onClick={() => claimCombo()}>
                      <img src={goldCoin} alt="" className="clamidBtn" /> Claim
                    </button>
                  ) : (
                    <button>Already Claimed</button>
                  )}
                </div>
              ) : (
                <button disabled={true}>
                  <img src={goldCoin} alt="" className="clamidBtn" /> +5,000,000
                </button>
              )}
            </div>
          </div>
          <div
            className="d-flex w-100 justify-content-between align-items-center"
            data-aos="fade-right"
          >
            {pattern?.map((item, index) => (
              <div key={index} className="mystery-card mt-4">
                {item === "Combo" ? (
                  <div className="position-relative inner-div text-center">
                    <img
                      src={imageUrls[`Combo-${index}`]}
                      alt="Combo"
                      className="gold-coins"
                    />
                    <img
                      src={goldCoins}
                      alt="Gold Coins"
                      className="gold-coins"
                    />
                  </div>
                ) : (
                  <div className="position-relative inner-div text-center">
                    <QuestionMarkImg className="question-mark" />
                    <img
                      src={goldCoins}
                      alt="Gold Coins"
                      className="gold-coins"
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="text-center mineNumberWrap mt-3">
            <p>
              <img src={goldCoin} alt="" />
              {formatNumberWithCommas(goldCoinCount)}
            </p>
          </div>
          <Tabs />
          <div
            className="circle-container mt-5"
            ref={circleRef}
            onClick={handleCircleClick}
            onTouchStart={handleTouchStart}
            style={tiltStyle}
            id="container"
            data-aos="zoom-in"
          >
            <img src={tapBunny} alt="Rabbit" />
            {user?.remainingPerMinute > 0 && positions.map((pos, index) => (
              <div
                key={pos.id}
                className="flying-number"
                style={{ top: pos.top, left: pos.left }}
              >
                {user?.multitap.enable
                  ? `+${user?.level?.levelNo + 2}`
                  : `+${user?.level?.levelNo}`}
              </div>
            ))}
          </div>
          <div className="text-white roundedGameWrap  mt-4 d-flex justify-content-between align-items-center">
            <div>
              <img src={EnergyImg} alt="" className="me-0" />{" "}
              <span className="energyNumber">{`${user?.remainingPerMinute >= 0 ? formatNumberWithCommas(
                  parseInt(user?.remainingPerMinute)
                ) : 0} 
              / ${formatNumberWithCommas(
                parseInt(user?.level?.oneTimeUpdate)
              )}`}</span>
            </div>
            <div className="boostWrap">
              <Link to="/boost" style={{ textDecoration: "none" }}>
                <img src={BoostImg} alt="" className="me-0" />{" "}
                <span className="energyNumber">Boost</span>
              </Link>
            </div>
          </div>
        </div>
      </div>

      {isPopupVisible && (
        <div className="deletePopupWrap cardRoundedWrap pt-3 pb-5 px-4">
          <div className="crossBtnWrap">
            <span onClick={hidePopup}>
              <CrossIcon />
            </span>
          </div>
          <div className="walletWrap mineImgPosition text-center mt-5 position-relative">
            <span className="circlePopup"></span>
            <div className="imgPosition">
              <img src={walletBunny} alt="" />
            </div>
            {/* <h1 className="mt-4">Connect your TON wallet</h1> */}
            <p>Find 3 combo cards and you will get a 5M coins</p>
          </div>
          <div className="btnsDivWrap airDropsBtnWrap mt-3">
            <button onClick={() => hidePopup()}>
              <WalletIcon /> &nbsp; Hope i am lucky enough!
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default Mine;
