import React, { useContext, useEffect, useState } from "react";
import { userInstance } from "../../../../config/axios";
import CardSpecial from "../cardSpecial";
import { CrossIcon } from "../../../../common-componants/icons/icons";
import UserContext from "../../../../context/userContext";
import goldCoin from "../../../../assets/GoldCoinIcon.png";
import bunnyCar from "../../../../assets/bunnyCar.png";
import { formatToK } from "../../utils";
import { getBackgroundColor } from "../../utils";
import "./mySpecial.css";
import "../tabCard/tabCard.css";
import { toast } from "react-toastify";
import FlyCoins from "../flyCoins";
import FlyCoinsSec from "../flyCoinsSec";

const MySpecial = () => {
  // const [time, setTime] = useState({
  //   hours: "00",
  //   minutes: "00",
  //   seconds: "00",
  // });
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [mines, setMines] = useState([]);
  const [selectedMine, setSelectedMine] = useState(null);
  const { user, setUser, walletVisible,specialPurchase,setSpecialPurchase } = useContext(UserContext);
  const [buyClicked, setBuyClicked] = useState(false)
  const [showAnimation, setShowAnimation] = useState(false)
  const [showAnimation2, setShowAnimation2] = useState(false)

  const [cooldowns, setCooldowns] = useState(() => {
    const savedCooldowns = localStorage.getItem("cooldowns");
    return savedCooldowns ? JSON.parse(savedCooldowns) : [];
  });

  const getSpecial = async() =>{
    const resp = await userInstance().post("/mines", {
      userId: user?._id,
      mineType: "Special",
    });
    setMines(resp.data.userOwnedMines);
  }
  useEffect(() => {
    (async () => {
      if(user){
        getSpecial()
      }
      else{
        setMines([])
      }
    })();
  }, []);

  // useEffect(() => {
  //   const updateClock = () => {
  //     const now = new Date();
  //     setTime({
  //       hours: String(now.getHours()).padStart(2, "0"),
  //       minutes: String(now.getMinutes()).padStart(2, "0"),
  //       seconds: String(now.getSeconds()).padStart(2, "0"),
  //     });
  //   };

  //   const timerId = setInterval(updateClock, 1000);
  //   return () => clearInterval(timerId);
  // }, []);

  useEffect(() => {
    localStorage.setItem("cooldowns", JSON.stringify(cooldowns));
  }, [cooldowns]);

  useEffect(() => {
    const interval = setInterval(() => {
      const now = Date.now();
      setCooldowns((prevCooldowns) =>
        prevCooldowns
          .map((cooldown) => {
            const remainingTime = Math.max(cooldown.expiry - now, 0);
            return {
              ...cooldown,
              timer: Math.floor(remainingTime / 1000),
            };
          })
          .filter((cooldown) => cooldown.timer > 0)
      );
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const showPopup = (mine) => {
    if (user.tokens < mine?.cost) return;
    setIsPopupVisible(true);
    setSelectedMine(mine);
  };

  const hidePopup = () => {
    setIsPopupVisible(false);
  };

  const confirmBuy = async () => {
    if(!buyClicked){
      try {
        if(walletVisible){
          setShowAnimation2(false)
          setShowAnimation(true)
        }else{
          setShowAnimation(false)
          setShowAnimation2(true)
        }
        setBuyClicked(true)
        const resp = await userInstance().post("/buyMines", {
          userId: user._id,
          mine: selectedMine,
        });
        setUser(resp.data);
        setBuyClicked(false)
        const timer = selectedMine.level * 5 * (selectedMine.level + 1);
        // Set cooldown timer for the selected mine
        const expiryTime = Date.now() + timer * 1000; // Example cooldown time in milliseconds
        setCooldowns((prevCooldowns) => [
          ...prevCooldowns,
          { mineId: selectedMine._id, expiry: expiryTime },
        ]);
        hidePopup();
      } catch (error) {
        toast.error(error.message,{toastId:'confirmBuy'})
      }
    }
  };


  useEffect(() => {
    if (specialPurchase) {
      const timer = setTimeout(() => {
        setSpecialPurchase(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [specialPurchase]);
  useEffect(() => {
    if (showAnimation) {
      const timer = setTimeout(() => {
        setShowAnimation(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [showAnimation]);

  useEffect(() => {
    if (showAnimation2) {
      const timer = setTimeout(() => {
        setShowAnimation2(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [showAnimation2]);
  

  const getCooldownTimer = (mineId) => {
    const cooldown = cooldowns.find((cd) => cd.mineId === mineId);
    return cooldown ? cooldown.timer : 0;
  };

  return (
    <div className="mx-auto MySpecial">
      {mines.length === 0 ? (
        <div className="MySpecial-card">
          <p>Buy at least one special card to enter the "Special" club</p>
        </div>
      ) : null}

      <div className="newSpecial-all-user-info ">
        {mines?.map((mine, index) => {
          const cooldownTimer = getCooldownTimer(mine._id);

          return (
            <div
              className="card-timer-wrapper"
              key={mine._id}
              onClick={() => showPopup(mine)}
            >
              {cooldownTimer > 0 ? (
                <div className="card-timer">
                  <div className="custom-timer">{cooldownTimer}</div>
                </div>
              ) : null}

              <CardSpecial
                key={mine._id}
                data={mine}
                bgColor={{ backgroundColor: getBackgroundColor(index) }}
              />
            </div>
          );
        })}
      </div>

      {isPopupVisible && (
        <div className="deletePopupWrap tabCrdPopup cardRoundedWrap pt-3 pb-5 px-3 pl-0">
          <div className="crossBtnWrap">
            <span onClick={hidePopup}>
              <CrossIcon />
            </span>
          </div>
          <div className="walletWrap mineImgPosition text-center mt-5">
            <img src={selectedMine.imgUrl || bunnyCar} alt="" />
            <h1 className="">{selectedMine?.designation}</h1>
            <p>
              {selectedMine?.specialSlogan}
            </p>
          </div>
          <div className="btnsDivWrap airDropsBtnWrap mt-3">
            <div className="text-center mineNumberWrap">
              <p>
                <img src={goldCoin} alt="" />
                {formatToK(selectedMine?.cost)}
              </p>
            </div>
            {getCooldownTimer(selectedMine._id) > 0 ? (
              <button>{getCooldownTimer(selectedMine._id)}</button>
            ) : (
              <button onClick={confirmBuy}>Go ahead</button>
            )}
          </div>
        </div>
      )}
      {showAnimation && <FlyCoins />}
      {(showAnimation2 || specialPurchase) && <FlyCoinsSec />}   
    </div>
  );
};

export default MySpecial;
