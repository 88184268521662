import React, { useContext, useState } from 'react';
import "./bottomBar.css";
import { AxeIcon, CoinSvg, ExportIcon, FriendsIcon, SVGComponent } from '../icons/icons';
import { Link } from 'react-router-dom';
import UserContext from '../../context/userContext';

function BottomBar() {
  const {user} = useContext(UserContext)
  const [activeIndex, setActiveIndex] = useState(null);

  const handleItemClick = (index, item) => {
    setActiveIndex(index);
    if (window.Telegram.WebApp && item.label !== "Play") {
      const { WebApp } = window.Telegram;
      WebApp.ready();
      WebApp.BackButton.show();
      WebApp.BackButton.onClick(() => {
        window.history.back();
        WebApp.BackButton.hide();
      });
    }
    if (window.Telegram.WebApp && item.label === "Play") {
      const { WebApp } = window.Telegram;
      WebApp.ready();
      WebApp.BackButton.hide();
    }
  };

  const items = [
    { id: 1, label: "Play", icons: <SVGComponent />, route: "/" },
    { id: 2, label: "Mine", icons: <AxeIcon />, route: "/mine" },
    { id: 3, label: "Friends", icons: <FriendsIcon />, route: "/friends" },
    { id: 4, label: "Earn", icons: <CoinSvg />, route: "/earn" },
    { id: 5, label: "Airdrop", icons: <ExportIcon />, route: "/airdrops" }
  ];

  return (
    <div className='bottomBarMainDiv'>
      <div className='d-flex justify-content-between align-items-center'>
        {items.map((item, index) => (
          <Link to={item?.route} style={{ textDecoration: "none" }} key={item.id} onClick={() => handleItemClick(index, item)}>
            <p
              className={`bottomBarItem ${activeIndex === index ? 'active' : ''} ${item.label === "Earn" ? 'earn-tab' : ''}`}             >
              {item.icons}
              <span>{item.label}</span>
              {item.label === "Earn" && !user?.claimedDailyReward && <span className="earn-dot"></span>}
            </p>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default BottomBar;
