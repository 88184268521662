import React, { useEffect, useState,useContext } from "react";
import "./air.css";
import coinImage from "../../assets/gold-coin.png";
import { CrossIcon, WalletIcon } from "../../common-componants/icons/icons";
import walletImg from "../../assets/wallet1.png";
import {
  useTonConnectUI,
  useTonAddress,
} from "@tonconnect/ui-react";
import { userInstance } from "../../config/axios";
import UserContext from "../../context/userContext";
import { toast } from "react-toastify";

function AirDrop() {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [tonConnectUI] = useTonConnectUI();
  const userFriendlyAddress = useTonAddress();
  const {user , setUser} = useContext(UserContext)
  // const wallet = useTonWallet();
  // const [connected, setConnected] = useState(false);
  const [confirmation, setConfirmation] = useState(false)

  // useEffect(() => {
  //   if (wallet && wallet.connected) {
  //     setConnected(true);
  //   }
  // }, [wallet]);

  const handleConnectClick = () => {
    tonConnectUI.openModal();
  };

  const saveWallet = async() => {
    if(userFriendlyAddress && user){
      try {
        const resp =await userInstance().post("/saveWallet",{
          userId: user.userId,
          address: userFriendlyAddress
        })
        setUser(resp.data)
      } catch (error) {
        toast.error(error.message,{toastId:"saveWallet"})
      }
    }
  }
  useEffect(()=>{
    saveWallet()
  },[userFriendlyAddress])

  const handleDisconnect = () => {
    tonConnectUI.disconnect();
    // setConnected(false);
    setConfirmation(false)
  };

  const showPopup = () => {
    setIsPopupVisible(true);
  };

  const hidePopup = () => {
    setIsPopupVisible(false);
  };

  const copyToClipboard = () => {
    try {
      if(userFriendlyAddress){
        navigator.clipboard.writeText(userFriendlyAddress);
        toast.success("copied",{toastId:"copied"})
      }
    } catch (error) {
      toast.error("unable to copy",{toastId:"clipboard"})
      console.error(error.message);
    }
  };

  const showConfirmationScreen = () =>{
    setConfirmation(true)
  }
  const clickedNo = () => {
    setConfirmation(false)
  }

  return (
    <>
      <div className="airDropsMainWrap px-3">
        <div
          className="glowing-container glowing-image-wrapper text-center"
          data-aos="fade-down"
        >
          <img src={coinImage} alt="Glowing Coin" className="glowing-image" />
        </div>
        <div className="contentMainWrap mt-4">
          <h1>Airdrop Tasks</h1>
          <p>
            Listing is on it's way. Tasks will appear below. Complete them to
            participate in the Airdrop.
          </p>
        </div>
        <div className="airDropsBtnWrap mt-4">
          <h2>Task List</h2>
          <button onClick={showPopup}>
            <WalletIcon /> &nbsp; Connect your TON wallet {userFriendlyAddress ? "✔"  :<div></div>}
          </button>
        </div>
      </div>

      {isPopupVisible && (
        <div className="deletePopupWrap cardRoundedWrap pt-3 pb-5 px-4">
          {userFriendlyAddress ? (
            <div>
              <div className="crossBtnWrap">
                <span onClick={hidePopup}>
                  <CrossIcon />
                </span>
              </div>
              {confirmation ? 
                <div>
                  <div className="walletWrap text-center">
                    <img src={walletImg} alt="" />
                    <h1>Are you sure want to disconnect your TON wallet?</h1>
                  </div>
                  <div className="btnsDivWrap airDropsBtnWrap mt-3">
                    <button onClick={handleDisconnect}>
                      YES
                    </button>
                    <button className="cancelBtn" onClick={clickedNo}>No</button>
                  </div>
                </div>
                :
                <div>
                  <div className="walletWrap text-center">
                    <img src={walletImg} alt="" />
                    <h1>Your TON wallet is connected</h1>
                    <p>You can disconnect it or copy wallet address.</p>
                  </div>
                  <div className="connectedWallet">
                    <span className="disconnect" onClick={showConfirmationScreen}>
                      <CrossIcon />
                    </span>
                    <button>
                      <div>
                      <WalletIcon /> &nbsp; {userFriendlyAddress.slice(0, 10)}.....
                      {userFriendlyAddress.slice(-8)}
                      </div>
                      <span className="ml-5" onClick={()=>copyToClipboard()} ><CopyIcon /></span>
                    </button>
                  </div>
                </div>
              }
              
            </div>
          ) : (
            <div>
              <div className="crossBtnWrap">
                <span onClick={hidePopup}>
                  <CrossIcon />
                </span>
              </div>
              <div className="walletWrap text-center">
                <img src={walletImg} alt="" />
                <h1>Connect your TON wallet</h1>
                <p>
                  Connect yout crypto wallet. If you don't have one, create one
                  in your Telegram account
                </p>
              </div>
              <div className="btnsDivWrap airDropsBtnWrap mt-3">
                <button onClick={handleConnectClick}>
                  <WalletIcon /> &nbsp; Connect your TON wallet
                </button>
                <button className="cancelBtn">Check</button>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default AirDrop;


const CopyIcon = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 18 18"
      style={{ width: "18px" }}
    >
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <path
          d="M9,7 C10.1045695,7 11,7.8954305 11,9 L11,16 C11,17.1045695 10.1045695,18 9,18 L2,18 C0.8954305,18 1.3527075e-16,17.1045695 0,16 L0,9 C-1.3527075e-16,7.8954305 0.8954305,7 2,7 L9,7 Z M8.5,9 L2.5,9 C2.25454011,9 2.05039163,9.17687516 2.00805567,9.41012437 L2,9.5 L2,15.5 C2,15.7454599 2.17687516,15.9496084 2.41012437,15.9919443 L2.5,16 L8.5,16 C8.74545989,16 8.94960837,15.8231248 8.99194433,15.5898756 L9,15.5 L9,9.5 C9,9.22385763 8.77614237,9 8.5,9 Z M16,0 C17.1045695,-2.02906125e-16 18,0.8954305 18,2 L18,9 C18,10.1045695 17.1045695,11 16,11 L13,11 L13,9 L15.5,9 C15.7454599,9 15.9496084,8.82312484 15.9919443,8.58987563 L16,8.5 L16,2.5 C16,2.22385763 15.7761424,2 15.5,2 L9.5,2 C9.25454011,2 9.05039163,2.17687516 9.00805567,2.41012437 L9,2.5 L9,5 L7,5 L7,2 C7,0.8954305 7.8954305,2.02906125e-16 9,0 L16,0 Z"
          fill="#bcbcbc"
        ></path>
      </g>
    </svg>
  );
};
