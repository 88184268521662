import React from 'react'
import Setting from '../../componants/setting'

function SettingPage() {
  return (
    <div>
      <Setting />
    </div>
  )
}

export default SettingPage
