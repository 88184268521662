import { useState, useEffect } from "react";

function useIntersectionObserver(ref, options) {
  const [isVisible, setIsVisible] = useState(true); // Default to true

  useEffect(() => {
    if (!ref.current) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      options
    );

    observer.observe(ref.current);

    return () => {
      if (ref.current) observer.unobserve(ref.current);
    };
  }, [ref, options]);

  return isVisible;
}

export default useIntersectionObserver;
