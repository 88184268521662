import React, { useEffect, useState } from "react";
import "../newSpecial/newSpecial.css";
import BannerImage from "../../../../assets/special-banner.jpg";
import { getBackgroundColor } from "../../utils";
import CardSpecial from "../cardSpecial";

const MissedSpecial = () => {
  // const [time, setTime] = useState({
  //   hours: "00",
  //   minutes: "00",
  //   seconds: "00",
  // });

  // useEffect(() => {
  //   const updateClock = () => {
  //     const now = new Date();
  //     setTime({
  //       hours: String(now.getHours()).padStart(2, "0"),
  //       minutes: String(now.getMinutes()).padStart(2, "0"),
  //       seconds: String(now.getSeconds()).padStart(2, "0"),
  //     });
  //   };

  //   const timerId = setInterval(updateClock, 1000);
  //   return () => clearInterval(timerId);
  // }, []);

  const data = [1, 1, 1, 1, 1, 1];
  
  const mine = {
    designation:"Expired",
    specialSlogan: "Games in paris",
    pph: 599,
    level: 1,
    cost: 1999,
    imgUrl: "https://bunny.websultanate.com/asset/bunny.jpg"
  }
  return (
    <div className="NewSpecial">
      {/* <div className="NewSpecial-banner">
        <img src={BannerImage} alt="" />

        <div className="overlay">
          <div>
            <h3 className=""> This is fine</h3>
            <p> Power of optimism</p>
            <div className="watch-timer">
              <span> ⏰</span>
              <span>{time.hours}</span>:<span>{time.minutes}</span>:
              <span>{time.seconds}</span>
            </div>
          </div>
        </div>
        <div className="NewSpecial-banner-bottom">
          <div className="newSpecial-bottom">
            <span>|v| 0</span>
            <div></div>
            <span>Profit per hour</span>
            <span>💲+3k</span>
            <div></div>
            <span>💰 35k</span>
          </div>
        </div>
      </div> */}
      <div className="newSpecial-all-user-info">
        {data.map((item, index) => (
          <CardSpecial
            // time={time}
            key={index}
            data={mine}
            bgColor={{ backgroundColor: getBackgroundColor(index) }}
          />
        ))}
      </div>
    </div>
  );
};

export default MissedSpecial;
