import React from 'react'
import ReciveGift from '../../componants/reciveGift'

function ReciveGiftPage() {
  return (
    <div>
      <ReciveGift />
    </div>
  )
}

export default ReciveGiftPage
