import React from 'react'
import Friends from '../../componants/friends'

function FriendsPage({id}) {
  return (
    <div>
      <Friends id={id} />
    </div>
  )
}

export default FriendsPage
