import React from "react";
import "./recive.css";
import BunnyGift from "../../assets/bunnyGift.png";
import goldCoin from "../../assets/GoldCoinIcon.png";
import { GiftImg } from "../../common-componants/icons/icons";

function ReciveGift() {
  return (
    <>
      <div className="reciveGiftMainWrap">
        <div className="reciveImgWrap px-3 text-center">
          <img src={BunnyGift} alt="" className="w-75 text-center m-auto" />
        </div>
        <div className="cardRoundedWrap p-4">
          <div className="reciveGiftWrap text-center">
            <h1>Click Here</h1>
          </div>
          <div className="circle-container deviceChanges" data-aos="zoom-in">
            {/* <img src={rabbitImg} alt="Rabbit" /> */}
            <GiftImg />
            {/* {flyNumber && <div className="flying-number">+1</div>} */}
            <div className="roundedGameWrap">
                <h1>Receive a gift</h1>
              <p>
                <img src={goldCoin} alt="" />
                100
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReciveGift;
