import React from 'react'
import Boost from '../../componants/boost'

function BoostPage() {
  return (
    <div>
      <Boost />
    </div>
  )
}

export default BoostPage
