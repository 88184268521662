import React from 'react'
import ChooseExchange from '../../componants/chooseExchange'

function ChooseExchangePage() {
  return (
    <div>
      <ChooseExchange />
    </div>
  )
}

export default ChooseExchangePage
