import React, { useState } from "react";
import "./tabSpecial.css";
import MissedSpecial from "../missedSpecial";
import NewSpecial from "../newSpecial";
import MySpecial from "../mySpecial";
// import TabCard from "../tabCard";

const TabSpecial = () => {
  const [activeTab, setActiveTab] = useState("tab1");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const setTab = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      <div className="special d-flex justify-contents-center aling-item-center">
        <div
          className={`tab ${activeTab === "tab1" ? "active" : ""}`}
          onClick={() => handleTabClick("tab1")}
        >
          My cards
        </div>
        <div
          className={`tab ${activeTab === "tab2" ? "active" : ""}`}
          onClick={() => handleTabClick("tab2")}
        >
          New cards
        </div>
        <div
          className={`tab ${activeTab === "tab3" ? "active" : ""}`}
          onClick={() => handleTabClick("tab3")}
        >
          Missed opportunities
        </div>
      </div>
      <div className="tab-content mt-3">
        {activeTab === "tab1" && (
          <div>
            <MySpecial />
          </div>
        )}
        {activeTab === "tab2" && (
          <div>
            <NewSpecial setTab={setTab}/>
          </div>
        )}
        {activeTab === "tab3" && (
          <div>
            <MissedSpecial />
          </div>
        )}
      </div>
    </>
  );
};

export default TabSpecial;
