import React from 'react'
import Earn from '../../componants/earn'

function EarnPage() {
  return (
    <div>
      <Earn />
    </div>
  )
}

export default EarnPage
