import React, { useState,useEffect,useContext } from "react";
import "./redChipper.css";
import { CoinIcon, EnergyIcon } from "../../common-componants/icons/icons";
import { Link } from "react-router-dom";
import BoostImg from "../../assets/launch.png";
import goldCoin from "../../assets/gold-coin.png";
import rabbitImg from "../../assets/rabbitGame-removebg-preview.png";
import tapBunny from "../../assets/bunnie.png"
import UserContext from "../../context/userContext";
import { userInstance } from "../../config/axios";
import { toast } from "react-toastify";
import { formatNumberWithCommas } from "../mine/utils";
import Zeus from "../../assets/tap-icons/Zeus.png";
import Hera from "../../assets/tap-icons/Hera.png";
import Poseidon from "../../assets/tap-icons/Poseidon.png";
import Athena from "../../assets/tap-icons/Athena.png";
import Krishna from "../../assets/tap-icons/Krishna.png";
import Varaha from "../../assets/tap-icons/Varaha.png";
import Rama from "../../assets/tap-icons/Rama.png";
import Odin from "../../assets/tap-icons/Odin.png";
import Loki from "../../assets/tap-icons/Loki.png";
import Thor from "../../assets/tap-icons/Thor.png";
import Balder from "../../assets/tap-icons/Balder.png";
import Wukong from "../../assets/tap-icons/Sun Wukong.png";
import { useLocation } from "react-router-dom";

const morseCodeMap = {
  A: ".-",
  B: "-...",
  C: "-.-.",
  D: "-..",
  E: ".",
  F: "..-.",
  G: "--.",
  H: "....",
  I: "..",
  J: ".---",
  K: "-.-",
  L: ".-..",
  M: "--",
  N: "-.",
  O: "---",
  P: ".--.",
  Q: "--.-",
  R: ".-.",
  S: "...",
  T: "-",
  U: "..-",
  V: "...-",
  W: ".--",
  X: "-..-",
  Y: "-.--",
  Z: "--..",
};

function RedChipper() {
  const {user, setUser} = useContext(UserContext);
  const [goldCoinCount, setGoldCoinCount] = useState("Loadin...");
  const [flyNumber, setFlyNumber] = useState(false);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const [userInput, setUserInput] = useState(""); 
  const [currentLetterIndex, setCurrentLetterIndex] = useState(0); 
  const [revealedWord, setRevealedWord] = useState(""); 
  const [tapType, setTapType] = useState()
  const [startTime, setStartTime] = useState(null);
  const [targetWord, setTargetWord] = useState("")
  const [morseClicked, setMorseClicked] = useState(false)
  const [exchangeIcon, setExchangeIcon] = useState()
  const location = useLocation()
  useEffect(()=>{
    if (window.Telegram.WebApp && location.pathname !== "/") {
      const { WebApp } = window.Telegram;
      WebApp.ready();
      WebApp.BackButton.show();
      WebApp.BackButton.onClick(() => {
        window.history.back();
        WebApp.BackButton.hide();
      });
    }
  },[])

  const exchanges = [
    { name: "Zeus", Icon: Zeus },
    { name: "Hera", Icon: Hera },
    { name: "Poseidon", Icon: Poseidon },
    { name: "Athena", Icon: Athena },
    { name: "Krishna", Icon: Krishna },
    { name: "Varaha", Icon: Varaha },
    { name: "Rama", Icon: Rama },
    { name: "Odin", Icon: Odin },
    { name: "Loki", Icon: Loki },
    { name: "Thor", Icon: Thor },
    { name: "Balder", Icon: Balder },
    { name: "Sun Wukong", Icon: Wukong },
  ];
  useEffect(()=>{
    exchanges.map((exchange) => {
      if(exchange.name === user?.selectedExchange ){
        setExchangeIcon(exchange.Icon)
      }
    })
  },[user])

  //-------for morse code-----------------
  const getTodaysWord = async() => {
    const resp = await userInstance().get("/getTodaysMorse");
    console.log(resp.data.word)
    setTargetWord(resp.data.word)
  }
  useEffect(()=>{
    getTodaysWord()
  },[])
  
  // const targetWord = "H"

  const handleTouchStart1 = async(event) => {
    if(revealedWord===targetWord){
      resetGame()
    }
    event.preventDefault();
    setStartTime(Date.now()); 
    const resp = await userInstance().post("/updateToken", {
      userId: user?._id
    });
    setUser(resp?.data?.updatedUser)
    setGoldCoinCount(parseFloat(resp?.data?.updatedUser?.tokensWhenComesOnline).toFixed())
  };
  const handleTouchEnd = (event) => {
    const endTime = Date.now();
    const duration = endTime - startTime;
    const touch = event.changedTouches[0]; // Get the first touch point
    const rect = event.target.getBoundingClientRect(); // Get bounding rectangle of the element
    const x = touch.clientX - rect.left; // Calculate X coordinate relative to the element
    const y = touch.clientY - rect.top;
    setPosition({ top: y, left: x });
    setFlyNumber(true);
    setTimeout(() => setFlyNumber(false), 1000);

    const input = duration < 300 ? "." : "-";
    setUserInput((prev) => prev + input);
    setTapType(input)

    const updatedInput = userInput + input;
    setUserInput(updatedInput); 

    const currentLetter = targetWord[currentLetterIndex]; 
    const currentMorseCode = morseCodeMap[currentLetter]; 

    if (updatedInput === currentMorseCode) {
      setRevealedWord((prev) => prev + currentLetter);
      setCurrentLetterIndex((prev) => prev + 1); // Move to the next letter
      setUserInput(""); // Reset user input for the next letter
    } else if (!currentMorseCode.startsWith(updatedInput)) {
      // Incorrect input: reset everything
      resetGame();
    }
    setStartTime(null); // Reset touch start time
  };
  const resetGame = () => {
    setUserInput("");
    setRevealedWord("");
    setCurrentLetterIndex(0);
  };

  const claimMorse = async () =>{
    if(!morseClicked){
      try {
        setMorseClicked(true)
        const resp = await userInstance().post("/claimMorse",{
          userId: user?._id,
        })
        setUser(resp.data)
        resetGame()
        toast.success("Claimed!",{toastId:"morse"})
      } catch (error) {
        toast.error(error.message,{toastId:"morseerror"})
        console.log("Error while claiming combo", error)
      }
    }
  }

  




  // const handleCircleClick = (event) => {
  //   const rect = event.target.getBoundingClientRect();
  //   const x = event.clientX - rect.left;
  //   const y = event.clientY - rect.top;

  //   setPosition({ top: y, left: x });
  //   setFlyNumber(true);
  //   setGoldCoinCount((prevCount) => prevCount + 1);
  //   setTimeout(() => setFlyNumber(false), 1000);
  // };

  useEffect(()=>{
    setGoldCoinCount(parseFloat(user?.tokensWhenComesOnline).toFixed());
  }, [user]);
  
  return (
    <div className="chipperMainWrap">
      <div className="roundedGameWrap  text-center mt-3">
        <div className="comboDailyWrap d-flex justify-content-between align-items-center py-2 px-3">
          <div>
            <span className="text">{revealedWord==="" ? "Easter eggs" : revealedWord}</span>
          </div>
          <div className="btnDiv">
            {user.claimedMorse ?
              <button>Claimed</button>
            : revealedWord===targetWord ?
                <button onClick={()=>claimMorse()}>Claim</button>
              :
                <button>
                  <CoinIcon /> +1,000,000
                </button>
            }
          </div>
        </div>
        <p className="mt-3">
          <img src={goldCoin} alt="" />
          {formatNumberWithCommas(goldCoinCount)}
        </p>
        <div
          className="circle-container redChipperPlate"
          // onClick={handleCircleClick}
          onTouchStart={handleTouchStart1}
          onTouchEnd={handleTouchEnd}
          onClick={(e)=>e.preventDefault()}
          data-aos="zoom-in"
        >
          <img src={tapBunny} alt="Rabbit" />
          {flyNumber && (
            <div
              className="flying-number"
              style={{ top: position.top, left: position.left }}
            >
              {tapType}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default RedChipper;
