import React from 'react'
import AirDrop from '../../componants/airDrops'

function AirDropsPage() {
  return (
    <div>
      <AirDrop />
    </div>
  )
}

export default AirDropsPage
