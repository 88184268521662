import React from 'react'
import LevelBronze from '../../componants/levels'

function LevelPage() {
  return (
    <div>
      <LevelBronze />
    </div>
  )
}

export default LevelPage
