import React from 'react'
import Mine from '../../componants/mine'

function MinePage() {
  return (
    <div>
      <Mine />
    </div>
  )
}

export default MinePage
