import React, { useEffect, useContext, useState } from "react";
import "./newSpecial.css";
import BannerImage from "../../../../assets/special-banner.jpg";
import CardSpecial from "../cardSpecial";
import { userInstance } from "../../../../config/axios";
import UserContext from "../../../../context/userContext";
import goldCoin from "../../../../assets/GoldCoinIcon.png";
import bunnyCar from "../../../../assets/bunnyCar.png";
import { CrossIcon } from "../../../../common-componants/icons/icons";
import { getBackgroundColor } from "../../utils";
import "../tabCard/tabCard.css";
import { formatToK } from "../../utils";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import FlyCoins from "../flyCoins";
import FlyCoinsSec from "../flyCoinsSec";

const NewSpecial = ({ setTab }) => {


  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [mines, setMines] = useState([]);
  const [selectedMines, setSelectedMines] = useState([]);
  const { user, setUser, walletVisible,setSpecialPurchase } = useContext(UserContext);
  const [updatedUser, setUpdatedUser] = useState(user)
  const [loading, setLoading] = useState(true);
  const [buyClicked, setBuyClicked] = useState(false)
  const [showAnimation, setShowAnimation] = useState(false)
  const [showAnimation2, setShowAnimation2] = useState(false)

  const getMines = async() => {
    try {
      const resp = await userInstance().post("/mines", {
        userId: user?._id,
        mineType: "Special",
      });
      setMines(resp.data.filteredMines);
      setLoading(false);
    } catch (error) {
      toast.error(error.message,{toastId:"getMines"})
    }
  }
  useEffect(() => {
    (async () => {
      if(user){
        getMines()
      }
      else{
        setMines([])
      }
    })();
  }, []);

  // useEffect(() => {
  //   const updateClock = () => {
  //     const now = new Date();
  //     setTime({
  //       hours: String(now.getHours()).padStart(2, "0"),
  //       minutes: String(now.getMinutes()).padStart(2, "0"),
  //       seconds: String(now.getSeconds()).padStart(2, "0"),
  //     });
  //   };

  //   const timerId = setInterval(updateClock, 1000);
  //   return () => clearInterval(timerId);
  // }, []);

  // const data = [1, 1, 1, 1, 1, 1];

  const showPopup = (data, isLock) => {
    if(isLock){
      setIsPopupVisible(false);
      setSelectedMines(data);
    }else{
      setIsPopupVisible(true);
      setSelectedMines(data);
    }
  };
  const hidePopup = () => {
    setIsPopupVisible(false);
  };

  const confirmBuy = async () => {
    if(!buyClicked){
      try {
        // if(walletVisible){
        //   setShowAnimation2(false)
        //   setShowAnimation(true)
        // }else{
        //   setShowAnimation(false)
        //   setShowAnimation2(true)
        // }
        setBuyClicked(true)
        const resp = await userInstance().post("/buyMines", {
          userId: user._id,
          mine: selectedMines,
        });
        setUser(resp.data);
        setUpdatedUser(resp.data)
        setSpecialPurchase(true)
        setTab("tab1");
        setBuyClicked(false)
        
        hidePopup();
      } catch (error) {
        toast.error(error.message,{toastId:"buyerror"})
      }
    }
  };

  useEffect(() => {
    if (showAnimation) {
      const timer = setTimeout(() => {
        setShowAnimation(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [showAnimation]);

  useEffect(() => {
    if (showAnimation2) {
      const timer = setTimeout(() => {
        setShowAnimation2(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [showAnimation2]);


  const isLocked = (data) => {
    const userSubscribed = data.dependency &&
      user?.minutlyGain?.subscribed?.find(
        (mine) => mine.designation === data.dependency.mine
    );
    if (data.dependency) {
      if (userSubscribed?.level >= data.dependency.requiredLevel) {
        return false;
      } else {
        return true;
      }
    }
  };

  return (
    <div className="NewSpecial">
      {loading ? (
        <Spinner animation="border" variant="primary" />
      ) : (
        <>
      <div className="NewSpecial-banner">
        <img src={BannerImage} alt="" />

        <div className="overlay">
          <div>
            <h3 className=""> This is fine</h3>
            <p> Power of optimism</p>
            {/* <div className="watch-timer">
              <span> ⏰</span>
              <span>{time.hours}</span>:<span>{time.minutes}</span>:
              <span>{time.seconds}</span>
            </div> */}
          </div>
        </div>
        <div className="NewSpecial-banner-bottom">
          <div className="newSpecial-bottom">
            <span>|v| 0</span>
            <div></div>
            <span>Profit per hour</span>
            <span>💲+3k</span>
            <div></div>
            <span>💰 35k</span>
          </div>
        </div>
      </div>
      <div className="newSpecial-all-user-info">
        {mines?.map((data, index) => {
          const isLock = isLocked(data)
          return (
            <div onClick={() => showPopup(data, isLock)}>
              <CardSpecial
                key={data?._id}
                data={data}
                isLock={isLock}
                bgColor={{ backgroundColor: getBackgroundColor(index) }}
              />
            </div>
          );
        })}
      </div>

      {isPopupVisible && (
        <div className="deletePopupWrap tabCrdPopup cardRoundedWrap pt-3 pb-5 px-3 pl-0">
          <div className="crossBtnWrap">
            <span onClick={hidePopup}>
              <CrossIcon />
            </span>
          </div>
          <div className="walletWrap mineImgPosition text-center mt-5">
            {/* <span className="circlePopup"></span> */}
            {/* <div className="imgPosition"> */}
            <img src={selectedMines.imgUrl} alt="" />
            {/* </div> */}
            <h1 className="">{selectedMines?.designation}</h1>
            <p>
              {selectedMines.specialSlogan}
            </p>
          </div>
          <div className="btnsDivWrap airDropsBtnWrap mt-3">
            <div className="text-center mineNumberWrap">
              <p>
                <img src={goldCoin} alt="" />
                {formatToK(selectedMines?.cost)}
              </p>
            </div>
            {updatedUser.tokens < selectedMines?.cost ?
            <button>Insufficient balance</button>
            :
            <button onClick={confirmBuy}>Go ahead</button>
            }
          </div>
        </div>
      )}
      {showAnimation && <FlyCoins />}
      {showAnimation2 && <FlyCoinsSec />}   
      </>
      )}
    </div>
  );
};

export default NewSpecial;
